.logTabs{
    color: #191d63 !important;
}

.chipGreen{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 8px;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 5px;
    line-height: 1.6;
    font-weight: 700;
    background: rgb(203, 244, 201);
    color: #178852;
}

.chipRed{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 8px;
    padding: 2px 8px;
    font-size: 13px;
    border-radius: 5px;
    line-height: 1.6;
    font-weight: 700;
    background: #fce2dd;
    color: #a51d50;
}