.headerLogo{
    width: 100%;
    max-width: 90px;
}
.mandatory{
    color:red;
}

.brokerText{
    font-size:10px;
    font-style: italic;
    margin-left:5px;
}

.navbarCustom{
    background-color:#191d63;
}

.text-bold{
    font-weight:bold;
}

.sign-out{
    font-size: 30px !important;
}

.sign-out:hover{
    cursor: pointer;
}

.nav-item{
    margin:10px;
    width:100%;
}

.cursorHover:hover{
    cursor: pointer
}

.marginZero{
    margin:0;
}

.footer{
    background-color:#191d63;
    width:100%;
}

.footer-item{
    color:white;
}

/* screen component style */

.screenContainer{
    display: flex;
    flex-direction: row;
}

.sidebarComponentClosed{
    flex: 0.05;
    background-color: #fff;
    box-shadow: rgba(150, 196, 255, 0.25) 0px 2px 8px;
    z-index:980;
    width: 70px;
    transition-duration: 0.5s;
}

.sidebarComponentOpened{
    flex: 0.17;
    background-color: #fff;
    box-shadow: rgba(150, 196, 255, 0.25) 0px 2px 8px;
    z-index:980;
    width: 250px;
    min-width:160px;
}

.mainComponentOpened{
    flex: 0.83;
    background-color: rgb(246,249,255);
    overflow: hidden;
}

.mainComponentClosed{
    flex: 0.95;
    background-color: rgb(246,249,255);
    overflow: hidden;
}

.sidebarToggleButton{
    padding:10px;
    text-align: center;
    background-color:darkgray;
    color:white;
    font-weight: bold;
}

.sidebarToggleButton:hover{
    cursor: pointer;
}

.sidebarContainer{
    width:100%;
}

.sideBarMenu{
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-weight: bold;
}

li.sideBarItem{
    padding:15px;
}

li.sideBarItemSelected{
    padding:15px;
    background-color:#00A3E0;
    color:white;
}

li.sideBarItem:hover,li.sideBarItemSelected:hover{
   cursor: pointer;
}

.sidebarIcon{
    margin-right:5px;
    font-size:25px !important;
}

.sidebarIconSelected{
    margin-right:10px;
    font-size:25px !important;
    color: white;
}

.sideBarItem.refreshItem:hover{
    color:#00A3E0;
}

.sideBarItem.refreshItem:hover .sidebarIcon.refreshIcon{
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

.speeddial{
    position: absolute;
    bottom:10px;
    right:10px;
}

.login-form{
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top:60px;
    margin-bottom:60px;
    box-shadow:0px 1px 10px 2px grey;
}

.btn-et{
    background-color:#191d63;
    border:0;
}

.btn-et:hover{
    background-color:#00A3E0;
}

.loginBg{
    /* background:url(../../assets/images//loginbg.jpg) repeat center center fixed; */
    
    background:url('../../assets/images//Login - Background 2.png') repeat center center fixed;
    background-size: cover;
}

.modal-header:hover{
    cursor: move;
    background-color:whitesmoke;
}

.mfaBanner{
    background-color: #00A3E0;
}

.mfaBanner:hover{
    cursor: pointer;
    background-color: #007ead;
}

@media screen and (max-width: 991px) {
    .portfolioDropDown{
        width:70%;
        display: block;
        margin: 0 auto;
    }
    .fullWidth{
        width:100%;
    }
}
  
@media screen and (min-width: 992px) {
    .portfolioDropDown{
        width:100%;
        min-width:400px;
        max-width:400px;
    }
}

