h1,h2,h3,h4,h5,h6,p,a,li,strong{
  font-family: Avenir;
}

.redText{
    color: red;
}

.greenText{
    color: green;
}

.boldText{
    font-weight: bold;
}

.marginBottomZero{
    margin-bottom: 0px;
}

button:focus{
    outline: none !important;
}