.dividendCard{
    width: 100%;
    height: 100%;
    padding: 5px;
    margin-top: 4px;
    border: 3px solid rgb(255, 255, 255);
    opacity: 1;
    display: block;
    color:white;
    transition: transform .5s;
}

.dividendCard:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.pay-date{
    background: cadetblue
}

.ann-date{
    background: #006600;
}

.exd-date{
    background: #D20000;
}

.borderBlack{
    border: 3px solid black;
}

.calendarViewMonth{
    padding:0;
}

.dividendCard > p{
    font-size:11px;
    font-weight:bold;
}

.calendarViewTBody > tr > td{
    padding:0px;
}