.ladderTitleBanner{
    text-align: center;
    color:white;
    font-weight:bold;
    padding:5px;
}

.buyLadder{
    background-color:green;
}

.sellLadder{
    background-color:#b52f2b;
}

.bold{
    font-weight: bold;
}