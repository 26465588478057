.statusCardHeader{
    background-color:#191d63;
    color:white;
    min-height:50px;
    width:100%;
}

.statusCardHeader:hover{
    cursor: pointer;
}

.statusCardHeaderTitle{
    margin:0;
    font-weight: bold;
}

.statusCardHeader.row,.card-body.row{
    margin-left:0;
    margin-right:0;
}

table {
    border-collapse: collapse;
    width: 100%;
    border:solid 1px white;
  }
  
  th, td {
    text-align: center;
    padding: 8px;
    border:solid 1px white;
  }
  
  tr:nth-child(even) {background-color: #f2f2f2;}

  .noBR{
      border-radius: 0;
  }

  table.summaryTable th{
    padding:0.75rem;
    font-weight:bold;
    background-color:#191d63;
    color:white;
    text-align: center;
    vertical-align: middle;
  }


  table.summaryTable th:hover{
    cursor: pointer;
  }

  .totalSummary{
      border-top:solid 1px black;
      border-bottom: solid 1px black;
      background-color:#eff0f1;
  }

  table.totalSummaryTable, table.totalSummaryTable td{
      border:0;
      font-weight:bold;
  }

  thead th.ascending::after {
    content: '🔼';
    display: inline-block;
  }
  
  thead th.descending::after {
    content: '🔽';
    display: inline-block;
  }