.latestTradeTable tbody tr td{
    padding:8px 5px 8px 0px;
    font-size:14px;
}

.buyColor{
    color:green;
}

.sellColor{
    color:red;
}

.boldText{
    font-weight: bold;
}

.headerTableContentHeight{
    min-height: 87%;
}

.aggregatedDashboardContainer{
    background-color: white;
    padding:10px;
}

.aggStatBox{
    padding:5px;
    border: solid 2px black;
    border-radius:5px;
}

.redBorder{
    border: solid 2px red;
}