.etBlueBanner{
    background-color:#191d63;
    padding:15px;
    flex-direction: row;
    flex:1;
    display: flex;
}

.etBlueBanner:hover{
    cursor: pointer;
    opacity: 0.95;
}

.etBannerTitle{
    color:white;
    font-weight: bold;
    text-align:center;
    margin-bottom:0;
}

.etBannerToggleIcon{
    color:white;
    font-size:25px;
}

.etBannerTitleFlex{
    flex:0.9;
    align-items: center;
    justify-content: center;
}

.etBannerToggleIconFlex{
    flex:0.1;
    align-items: center;
    justify-content: center;
}

.etBannerContent{
    padding:10px;
    border:solid 1px #dcdcdc;
    border-radius:5px;
}